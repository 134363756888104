import { addMonths, endOfMonth } from 'date-fns';

import { createSlice } from '@reduxjs/toolkit';

const todayDate: any = new Date();

const initialState: any = {
  value: {
    startDate: endOfMonth(addMonths(todayDate, -1)).toISOString(),
    endDate: endOfMonth(todayDate).toISOString(),
    currentDate: todayDate.toISOString(),
    monthData: [],
    data: [],
    selectedData: {},
  },
};

export const agendaSlice = createSlice({
  name: 'agendaData',
  initialState,
  reducers: {
    setAgendaData: (state: any, action: any) => {
      state.value = { ...state.value, ...action.payload };
    },
    deleteAgendaEvent: (state: any, action: any) => {
      state.value = {
        ...state.value,
        data: [...state.value.data.filter((item: any) => item.id !== action.payload.id)],
        monthData: [...state.value.monthData.filter((item: any) => item.id !== action.payload.id)],
        selectedData: {},
      };
    },
  },
});

export const { setAgendaData, deleteAgendaEvent }: any = agendaSlice.actions;

export const getAgendaData = (state: any) => state.agendaData.value;

export default agendaSlice.reducer;
