import {ApiRequests, Endpoints, IdOf, Model} from '@ward/library';

// Student
function getStudent(id: number | 'current') {
  return ApiRequests.fromEndpoint(Endpoints.students.getStudent, {
    pathParams: {id},
    queryParams: null,
    data: null,
    files: null,
  });
}

function editStudent(data?: { biography?: string, onboarded?: boolean }) {
  return ApiRequests.fromEndpoint(Endpoints.students.updateStudent, {
    pathParams: { id: 'current' },
    queryParams: null,
    data: data || {},
    files: null,
  });
}

function updateStudentPhoto(photo: Blob | null) {
  return ApiRequests.fromEndpoint(Endpoints.students.updateStudentPhoto, {
    pathParams: { id: 'current' },
    queryParams: null,
    data: null,
    files: { photo },
  });
}

// Report student

function reportStudent(student: IdOf<Model.Student>, reason: string) {
  return ApiRequests.fromEndpoint(Endpoints.students.reportStudentProfile, {
    pathParams: {student},
    queryParams: null,
    data: {reason},
    files: null,
  });
}

// Assistance

function getAssistances(queryParams?: { search?: string, limit?: number, shortlisted?: boolean }) {
  return ApiRequests.fromEndpoint(Endpoints.assistances.getAssistances, {
    pathParams: null,
    queryParams: queryParams || {},
    data: null,
    files: null,
  });
}

function createStudentAssistance(assistance: number) {
  return ApiRequests.fromEndpoint(Endpoints.students.addStudentAssistance, {
    pathParams: { student: 'current' },
    queryParams: null,
    data: { id: assistance },
    files: null,
  });
}

function removeStudentAssistance(assistance: number) {
  return ApiRequests.fromEndpoint(Endpoints.students.deleteStudentAssistance, {
    pathParams: { student: 'current', assistance},
    queryParams: null,
    data: null,
    files: null,
  });
}

// Hobbies

function getHobbies(queryParams?: { search?: string, limit?: number, shortlisted?: boolean }) {
  return ApiRequests.fromEndpoint(Endpoints.hobbies.getHobbies, {
    pathParams: null,
    queryParams: queryParams || {},
    data: null,
    files: null,
  });
}

function createStudentHobby(hobby: number) {
  return ApiRequests.fromEndpoint(Endpoints.students.addStudentHobby, {
    pathParams: { student: 'current' },
    queryParams: null,
    data: { id: hobby },
    files: null,
  });
}

function removeStudentHobby(hobby: number) {
  return ApiRequests.fromEndpoint(Endpoints.students.deleteStudentHobby, {
    pathParams: { student: 'current', hobby},
    queryParams: null,
    data: null,
    files: null,
  });
}

function suggestHobby(label: string) {
  return ApiRequests.fromEndpoint(Endpoints.students.addStudentHobby, {
    pathParams: {student: 'current'},
    queryParams: null,
    data: {label},
    files: null,
  });
}

// Companies

function getCompanies(search?: string, limit?: number) {
  return ApiRequests.fromEndpoint(Endpoints.companies.getCompanies, {
    pathParams: null,
    queryParams: {search: search || undefined, limit},
    data: null,
    files: null,
  });
}

// Topics

function getTopics() {
  return ApiRequests.fromEndpoint(Endpoints.topics.getTopics, {
    pathParams: null,
    queryParams: null,
    data: null,
    files: null,
  });
}

// Associations

function getAssociations() {
  return ApiRequests.fromEndpoint(Endpoints.associations.getAssociations, {
    pathParams: null,
    queryParams: null,
    data: null,
    files: null,
  });
}

const requests = {
  getStudent,
  editStudent,
  updateStudentPhoto,
  reportStudent,
  getAssistances,
  createStudentAssistance,
  removeStudentAssistance,
  getHobbies,
  createStudentHobby,
  removeStudentHobby,
  suggestHobby,
  getCompanies,
  getTopics,
  getAssociations,
};

export default requests;
