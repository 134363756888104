import {
  AppBar,
  Box,
  Container,
  Icon,
  Stack,
  Toolbar,
  Typography,
  alpha,
  useScrollTrigger,
  useTheme,
} from '@mui/material';
import { DefaultRoute, Routes } from '../../../core/router';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { ReactElement, cloneElement } from 'react';
import type { SxProps, Theme } from '@mui/system';

import AppLogo from 'assets/common/emn-logo.svg';
import AppTopBarMenu from './AppTopBarMenu';
import ChatNotifications from './ChatNotificationsContainer';
import Services from 'utilities/Services';
import StudentAvatar from '../../pages/student/components/StudentAvatar';
import WardIcon from '../../../core/utils/Icon';
import { getIsToolbox } from '../menus/toolbox/isToolboxSlice';
import { lighten } from '@mui/material/styles';
import themeVariable from '../../theme';
import { useSelector } from 'react-redux';
import authentication from 'core/api/authentication';
import { MicrosoftAuthenticationService } from '@ward/library';

export const APPBAR_HEIGHT = 78;
const EMNIconWidth = APPBAR_HEIGHT;

const containerStyle: SxProps<Theme> = {
  position: 'relative',
  height: APPBAR_HEIGHT,
  paddingLeft: 4,
  backgroundColor: 'transparent',
  minWidth: '1024px',
  marginLeft: 0,
  marginRight: 'auto',
  [themeVariable.breakpoints.up('xl')]: {
    marginLeft: 'auto',
    paddingRight: 4,
  },
};

const headerTopStyle: SxProps<Theme> = {
  background: theme => theme.palette.primary.main,
  '&.MuiPaper-elevation0': {
    background: 'transparent',
  }
};

const appIconStyle: SxProps<Theme> = {
  height: APPBAR_HEIGHT,
  width: EMNIconWidth,
  backgroundColor: 'red',
  zIndex: 2,
};

const wardStyle: SxProps<Theme> = {
  display: 'flex',
  width: 189,
  minWidth: 189,
  height: APPBAR_HEIGHT,
  borderRadius: '0 0 24px 0',
  backgroundColor: 'primary.dark',
  zIndex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': {
    backgroundColor: theme => lighten(theme.palette.primary.dark, .05),
  },

  [themeVariable.breakpoints.down('md')]: {
    display: 'none',
  },

  h2: {
    fontSize: 20,
    fontWeight: 700,
    letterSpacing: '1px',
    margin: 0,
  },
};

const wardIconStyle: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  marginRight: 2,
  opacity: 0.7,
};

const toolboxLinkStyle: SxProps<Theme> = {
  color: theme => theme.palette.primary.contrastText,
  margin: 'auto',
  fontSize: 10,
  letterSpacing: '0.27em',
  textAlign: 'center',
  fontWeight: 600,
};

const userStyle: SxProps<Theme> = {
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  paddingRight: '15px',
  marginLeft: '100px',
  [themeVariable.breakpoints.down('xl')]: {
    marginLeft: '75px',
  },
  [themeVariable.breakpoints.down('xl')]: {
    marginLeft: '50px',
  },
  [themeVariable.breakpoints.down('lg')]: {
    marginLeft: '20px',
  },
  [themeVariable.breakpoints.down('md')]: {
    marginLeft: '15px',
  },

  [themeVariable.breakpoints.down(1240)]: {
    position: 'absolute',
    top: '0',
    right: '20px',
  },
};

const userContentStyle: SxProps<Theme> = {
  [themeVariable.breakpoints.down(1240)]: {
    display: 'none',
  },
};

const usernameStyle: SxProps<Theme> = {
  color: theme => theme.palette.primary.contrastText,
  marginLeft: 2,
  fontSize: 11,
  fontWeight: 700,
};

const userGradeStyle: SxProps<Theme> = {
  fontWeight: 600,
  fontSize: 10,
  letterSpacing: '0.15em',
  marginTop: 0.5,
};

const logoutButtonStyle: SxProps<Theme> = {
  cursor: 'pointer',
};

const logoutStyle: SxProps<Theme> = {
  marginLeft: 2,
  fontWeight: 500,
  fontSize: 9,
  letterSpacing: '0.15em',
  marginTop: 1,
  color: theme => alpha(theme.palette.primary.contrastText, .39),

  '&:hover': {
    color: theme => alpha(theme.palette.primary.contrastText, .6),
  },
};

const chatStyle: SxProps<Theme> = {
  marginLeft: 4,
};

interface ElevationScrollProps {
  children: ReactElement;
}

function ElevationScroll(props: ElevationScrollProps) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const AppTopBar = () => {
  const { t } = useTranslation<Namespace>('common');
  const themeVariable = useTheme();
  const { chat, whoami } = Services.useAll();
  const isReadyChat = chat.useIsReady();
  const me = whoami.useMe();
  const isToolbox = useSelector(getIsToolbox);

  const logout = async () => {
    localStorage.clear();
    if ((authentication as MicrosoftAuthenticationService).buildSingleLogOutWebPageUrl) {
      const dest = await (authentication as MicrosoftAuthenticationService).buildSingleLogOutWebPageUrl();
      window.location.href = dest;
    } else {
      window.location.href = "/";
    }
  };
  //TODO internationalize
  return (
    <>
      <ElevationScroll>
        <AppBar sx={headerTopStyle}>
          <Container maxWidth="lg" sx={containerStyle} disableGutters>
            <Toolbar disableGutters>
              <Link
                to={DefaultRoute}
                style={{
                  textDecoration: 'none',
                  position: 'relative',
                  zIndex: 6,
                }}
              >
                <Stack direction="row">
                  <Icon sx={appIconStyle}>
                    <img src={AppLogo} alt="EMN Logo" />
                  </Icon>
                  <Stack direction="row" sx={wardStyle}>
                    <Box sx={wardIconStyle}>
                      <WardIcon icon="feature_menu_outline" size={28} />
                    </Box>
                    <Typography variant="h2" sx={toolboxLinkStyle}>
                      {t('common:appname')}
                    </Typography>
                  </Stack>
                </Stack>
              </Link>

              <AppTopBarMenu />

              {me && (
                <Box sx={userStyle}>
                  <StudentAvatar
                    image={me.photo}
                    link={Routes.Student}
                    iconBgColor={themeVariable.palette.secondary.main}
                  />
                  <Box sx={userContentStyle}>
                    <NavLink to={Routes.Student}>
                      <Typography
                        variant="topbar"
                        component="div"
                        sx={usernameStyle}
                      >
                        {me?.firstname} {me?.lastname}
                        <Typography
                          variant="h3"
                          sx={userGradeStyle}
                          color={isToolbox ? 'primary' : '#DF0015'}
                        >
                          {me.current_grade}
                        </Typography>
                      </Typography>
                    </NavLink>
                    <Box onClick={logout} sx={logoutButtonStyle}>
                      <Typography sx={logoutStyle}>
                        <WardIcon icon='navigation_leave' size={10} />
                        Déconnexion
                      </Typography>
                    </Box>
                  </Box>

                  {isReadyChat &&
                    <Box sx={chatStyle}>
                      <ChatNotifications />
                    </Box>
                  }
                </Box>
              )}
            </Toolbar>
          </Container>
        </AppBar>
      </ElevationScroll>
      <Toolbar sx={containerStyle} disableGutters />
    </>
  );
};

export default AppTopBar;
