import { Avatar, Box, Button } from '@mui/material';
import type { SxProps, Theme } from '@mui/system';

import { FC } from 'react';
import { Icon } from '../../../../core/utils';
import { Link } from 'react-router-dom';
import { Routes } from '../../../../core/router';
import { lighten } from "@mui/material/styles";

const containerStyle: SxProps<Theme> = {
    position: 'relative',
    height: 61,
    width: 61,
    padding: '3px',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: (theme) => theme.palette.primary.light,
    borderRadius: '100%',
};

const containerBigStyle: SxProps<Theme> = {
    height: 169,
    width: 169,
    padding: '8px',
};

const avatarStyle: SxProps<Theme> = {
    height: 53,
    width: 53,
};

const avatarBigStyle: SxProps<Theme> = {
    height: 151,
    width: 151,
};

const settingStyle: SxProps<Theme> = {
    position: 'absolute',
    top: 'calc(16% - 9px)',
    right: 'calc(16% - 9px)',
    padding: 0,
    background: (theme) => theme.palette.primary.main,
    borderRadius: ' 50%',
    display: 'flex',
    border: '1px solid #7A80A7',
    alignItems: 'center',
    justifyContent: 'center',
    width: 18,
    height: 18,
    minWidth: 'auto',
    zIndex: 1,
    color: (theme) => theme.palette.primary.contrastText,
    '&:hover': {
        background: (theme) => lighten(theme.palette.primary.main, 0.1),
    },
};

const settingBigStyle: SxProps<Theme> = {
    top: 'calc(15% - 26px)',
    right: 'calc(15% - 26px)',
    width: 47,
    height: 47,
};

type StudentAvatarProps = {
  image?: string | null,
  link?: any,
  icon?: string,
  iconLink?: any,
  iconBgColor?: string,
  big?: boolean,
  hideIcon?: boolean,
};

const StudentAvatar: FC<StudentAvatarProps> = ({ image, link, icon, iconLink, iconBgColor, big, hideIcon }) => {
  const renderAvatar = () =>
    image
      ? <Avatar sx={[avatarStyle, big ? avatarBigStyle : {}]} src={image} />
      : null;

  const iconBgColorStyles = iconBgColor
    ? {
      backgroundColor: iconBgColor,
      '&:hover': {
        backgroundColor: lighten(iconBgColor, .2),
      },
    }
    : {};

  return (
    <Box sx={[containerStyle, big ? containerBigStyle : {}]}>
      {!hideIcon &&
        <Link to={iconLink || Routes.StudentSettings}>
          <Button sx={[
            settingStyle,
            big ? settingBigStyle : {},
            iconBgColorStyles,
          ]}>
            <Icon icon={icon || 'feature_settings'} size={big ? 26 : 10} />
          </Button>
        </Link>
      }
      {link
        ? (
          <Link to={link}>
            {renderAvatar()}
          </Link>
        ) : renderAvatar()
      }
    </Box>
  );
};

export default StudentAvatar;
