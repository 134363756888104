import {
  Button,
  Grid,
  Typography,
} from '@mui/material';
import { lighten } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import type { SxProps, Theme } from '@mui/system';
import {
  getIsExperiences,
  setIsExperiences,
} from '../menus/experiences/isExperiencesSlice';
import { useDispatch, useSelector } from 'react-redux';

import { Icon } from 'core/utils';
import { Routes } from '../../../core/router';
import { useEffect } from 'react';

export const AppBarHeight = 78;

const toolboxStyle: SxProps<Theme> = theme => ({
  padding: '26px 41px 26px 61px',
  marginLeft: '-20px',
  marginRight: '30px',
  // background: theme.palette.gradient.main,
  background: 'linear-gradient(160deg, #ab0926, #0046c9)',
  '&:hover': {
    // background: theme.palette.gradient.mainHover,
    background: `linear-gradient(160deg, ${lighten('#ff0000', .1)}, ${lighten('#0046c9', .1)})`,
  },

  [theme.breakpoints.down('lg')]: {
    paddingLeft: '46px',
    marginRight: '20px',
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: '35px',
    marginRight: '10px',
  },

  '& .MuiTypography-topbar': {
    fontWeight: 600,
  },

  svg: {
    display: 'flex',
    // color: theme.palette.primary.contrastText,
    color: 'wheat'
  },
  '& .toolboxIcon': {
    display: 'block',
  },
  '& .toolboxIconActivated': {
    display: 'flex',
  },
});

const navButtonStyle: SxProps<Theme> = {
  textDecoration: 'none',
  position: 'relative',
  zIndex: 5,
  padding: '0px',
  '&:hover': {
    background: 'transparent',
  },
};

const navMenuStyle: SxProps<Theme> = theme => ({
  display: 'flex',
  alignItems: 'center',
  padding: '33px 41px 19px',
  height: '78px',
  borderRadius: '0 0 25px 0',

  '&:hover .MuiTypography-topbar': {
    fontWeight: 600,
  },

  [theme.breakpoints.down('lg')]: {
    paddingLeft: '25px',
    paddingRight: '25px',
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: '15px',
    paddingRight: '15px',
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '15px',
    paddingRight: '15px',
  },
});

const navMenuActivatedStyle: SxProps<Theme> = {
  '&:after': {
    content: "''",
    position: 'absolute',
    background: '-webkit-linear-gradient(-45deg, rgba(255,0,0,.5)5%,rgba(0,70,201,1)100%)',
    height: '15px',
    width: '80%',
    top: 0,
    left: '10%',
    right: '10%',
  },
};

const mainLinkPrincipalStyle: SxProps<Theme> = {
  color: theme => theme.palette.primary.contrastText,
  marginLeft: 3,
  fontSize: 10,
  letterSpacing: '0.27em',
  textAlign: 'center',
  fontWeight: 500,
};

const mainLinkStyle: SxProps<Theme> = {
  color: theme => theme.palette.primary.contrastText,
  margin: 'auto',
  fontSize: 10,
  letterSpacing: '0.27em',
  textAlign: 'center',
  fontWeight: 500,
};
const AppTopBarMenu = () => {
  const { t } = useTranslation<Namespace>('menu');
  const isExperiences = useSelector(getIsExperiences);
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const open = Boolean(anchorEl);
  // const handleClick = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  useEffect(() => {
    return history.listen(() => {
      dispatch(setIsExperiences(false));
      document.title = "EM Normandie";
    });
  }, [dispatch, history]);

  useEffect(() => {
    dispatch(setIsExperiences(isExperiences));
  }, [dispatch, isExperiences]);

  const isOpenedMenu = isExperiences;
  const isNews = (pathname === Routes.News) && !isOpenedMenu;
  const isInformations = (pathname === Routes.Informations) && !isOpenedMenu;
  const isContactForm = (pathname === Routes.ContactForm) && !isOpenedMenu;

  // const ContactMenuComponent = () => {
  //   return (<><Box sx={mainLinkStyle} >
  //     <Button
  //       onClick={handleClick}
  //       size="small"
  //       sx={{ ml: 2 }}
  //       aria-controls={open ? 'account-menu' : undefined}
  //       aria-haspopup="true"
  //       aria-expanded={open ? 'true' : undefined}
  //     >
  //       <Typography variant="topbar" sx={mainLinkStyle}>
  //         {t('contacts')}
  //       </Typography>
  //     </Button>
  //   </Box>
  //     <Menu
  //       sx={{ backgroundColor: 'transparent', color: 'white' }}
  //       anchorEl={anchorEl}
  //       id="account-menu"
  //       open={open}
  //       onClose={handleClose}
  //       onClick={handleClose}
  //       PaperProps={{
  //         elevation: 0,
  //         sx: {
  //           overflow: 'visible',
  //           filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
  //           mt: 1.5,
  //           '&:before': {
  //             content: '""',
  //             display: 'block',
  //             position: 'absolute',
  //             top: 0,
  //             right: 0,
  //             width: 10,
  //             height: 10,
  //             bgcolor: 'transparent',
  //             transform: 'translateY(-50%) rotate(45deg)',
  //             zIndex: 0,
  //           },
  //         },
  //       }}
  //       transformOrigin={{ horizontal: 'right', vertical: 'top' }}
  //       anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
  //     >
  //       <MenuItem style={{ backgroundColor: 'transparent' }} onClick={handleClose}>
  //         <NavLink end to={Routes.Suggestion}>
  //           <Fab>
  //             Bugs
  //           </Fab>
  //         </NavLink>
  //       </MenuItem>
  //       <MenuItem onClick={handleClose}>
  //         <NavLink end to={Routes.ContactForm}>
  //           <Fab>
  //             Contact
  //           </Fab>
  //         </NavLink>
  //       </MenuItem>
  //     </Menu>
  //   </>)
  // }

  return (
    <>
      <Button
        disableRipple
        disableFocusRipple
        onClick={() => {
          dispatch(setIsExperiences(!isExperiences));
        }}
        sx={navButtonStyle}
      >
        <Grid sx={[navMenuStyle, toolboxStyle]}>
          <Icon icon='navigation_toolbox' size={24} />
          <Typography variant="topbar" sx={mainLinkPrincipalStyle}>
            {t('my experience')}
          </Typography>
        </Grid>
      </Button>
      <NavLink to={Routes.News}>
        <Button
          sx={[navButtonStyle, isNews ? navMenuActivatedStyle : {}]}
          disableRipple
          disableFocusRipple
        >
          <Grid sx={[navMenuStyle]}>
            <Typography variant="topbar" sx={mainLinkStyle}>
              {t('news')}
            </Typography>
          </Grid>
        </Button>
      </NavLink>
      <NavLink strict to={Routes.Informations}>
        <Button sx={[navButtonStyle, isInformations ? navMenuActivatedStyle : {}]}>
          <Grid sx={[navMenuStyle]}>
            <Typography variant="topbar" sx={mainLinkStyle}>
              {t('information')}
            </Typography>
          </Grid>
        </Button>
      </NavLink>
      <NavLink strict to={`${Routes.Business}/9789`}>
        <Button sx={[navButtonStyle, isContactForm ? navMenuActivatedStyle : {}]}>
          <Grid sx={[navMenuStyle]}>
            <Typography variant="topbar" sx={mainLinkStyle}>
              {t('contact')}
            </Typography>
          </Grid>
        </Button>
      </NavLink >
    </>
  );
};

export default AppTopBarMenu;
