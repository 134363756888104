import {
  ApiRequests,
  ApiService,
  AuthenticationService,
  Endpoints,
  useEmittedState,
} from '@ward/library';

import EventEmitter from 'events';
import {Me} from './Me';

type WhoAmIServiceDeps = {
  api: ApiService;
  authentication: AuthenticationService;
};

const getCurrentUserRequest = ApiRequests.fromEndpoint(
  Endpoints.students.getStudent,
  {
    pathParams: {id: 'current'},
    queryParams: null,
    data: null,
    files: null,
  },
);

export default class WhoAmIService {
  private me: Me | null = null;
  private emitter = new EventEmitter();
  api: ApiService;
  authentication: AuthenticationService;

  constructor(deps: WhoAmIServiceDeps) {
    this.api = deps.api;
    this.authentication = deps.authentication;
  }

  async init() {
    if (this.authentication.getKeysPresence()) {
      try {
        const me = await this.fetchMe();
        this.setMe(me);
      } catch (err) {
        this.logout();
      }
    }
    return this;
  }

  useIdentity(): Me | null {
    return useEmittedState(() => this.me, this.emitter);
  }

  useMe(): Me {
    const me = this.useIdentity();
    if (!me) throw new Error('No me');
    return me;
  }

  useMyId(): Me['id'] {
    const me = this.useMe();
    if (!me) throw new Error('No me');
    return me.id;
  }

  async fetchMe(forceFetching: boolean = false) {
    return this.api.fetch(getCurrentUserRequest, {forceFetching});
  }

  async reloadMe() {
    if (!this.me) throw new Error('No me');
    const newMe = await this.fetchMe(true);
    this.updateMe(newMe);
  }

  setMe(me: Me) {
    const idChanged = !this.me || this.me.id !== me.id;
    this.me = me;
    this.emitter.emit('change', me);
    if (idChanged) this.emitter.emit('id_change', me);
  }

  updateMe(updater: ((me: Me) => Me) | Me) {
    const currentMe = this.me;
    if (!currentMe) throw new Error('Cannot update Me without previous Me');
    const newMe = typeof updater === 'function' ? updater(currentMe) : updater;
    if (currentMe.id !== newMe.id)
      throw new Error('Cannot change Me.id while updating');
    this.me = newMe;
    this.emitter.emit('change', newMe);
  }

  logout() {
    this.onboardingSkipped = false;
    this.me = null;
    this.emitter.emit('change', null);
    this.emitter.emit('id_change', null);
    this.authentication.clean();
  }

  getIdentity() {
    return this.me;
  }

  onChange(cb: () => any) {
    this.emitter.on('change', cb);
  }

  onIdChange(cb: () => any) {
    this.emitter.on('id_change', cb);
  }

  /** Temporary */

  private onboardingSkipped = false;

  useShowOnboarding() {
    return useEmittedState(() => {
      if (this.onboardingSkipped) return false;
      if (!this.me) return false;
      return this.me.onboarded ? false : true;
    }, this.emitter);
  }

  skipOnboarding() {
    this.onboardingSkipped = true;
    this.emitter.emit('change');
  }

  removeOnboardingSkip() {
    this.onboardingSkipped = false;
    this.emitter.emit('change');
  }
}
