import './utilities/i18n';

import App from './components';
import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from "react-dom/client";
import authentication from './core/api/authentication';
import reportWebVitals from './reportWebVitals';

authentication.init().then(async () => {
  const hasJustLoggedInUsingSSO =
    await authentication.analyseRedirection(window.location.href)
  try {
    authentication.getAuthorizationHeader();
  } catch (err) {
    authentication.clean();
  }
  if (hasJustLoggedInUsingSSO) {
    window.location.href = '/';
  } else {
    startApp();
  }
});

//  const envKeys = Object.keys(env).reduce((prev, next) => {
//     prev[`process.env.${next}`] = JSON.stringify(env[next]);
//     return prev;
//   }, {});
const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

function startApp() {
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}


// from React 17
// function startApp() {
//   ReactDOM.render(
//     <React.StrictMode>
//       <App />
//     </React.StrictMode>,
//     document.getElementById('root'),
//   );
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
