import { Box, SxProps, Theme, alpha } from '@mui/material';

import { FC } from 'react';
import { Icon } from 'core/utils';
import { Link } from 'react-router-dom';
import { Routes } from 'core/router';

const notificationStyle: SxProps<Theme> = {
  position: 'relative',
  display: 'flex',
  height: 48,
  minWidth: 48,
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid',
  borderColor: theme => theme.palette.primary.contrastText,
  borderRadius: '24px',
  '&:hover': {
    borderColor: theme => alpha(theme.palette.primary.contrastText, .8),
  },

  svg: {
    marginLeft: '2px',
    marginBottom: '2px',
  }
};

const counterStyle: SxProps<Theme> = {
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  top: -5,
  right: -5,
  width: 18,
  height: 18,
  fontSize: 8,
  fontWeight: 700,
  color: theme => theme.palette.primary.dark,
  background: theme => `linear-gradient(${theme.palette.primary.contrastText}, #B1C0FF)`,
  borderRadius: '50%',
};

type ChatNotificationsProps = {
  nbUnreadMessages: number,
};

const ChatNotifications: FC<ChatNotificationsProps> = ({ nbUnreadMessages }) =>
  <Link to={Routes.Chat}>
    <Box sx={notificationStyle}>
      <Icon icon='navigation_chat' size={23} />
      {nbUnreadMessages > 0 &&
        <Box sx={counterStyle}>
          {nbUnreadMessages}
        </Box>
      }
    </Box>
  </Link>

export default ChatNotifications;